:root {
  --primary: #7A4C2C;
  --secondary: #718F94;
  --black: #373737;
  --white: #F4F4F8;
  --nude: #E5D2C4;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
  background: var(--white);
  font-family: "Source Sans Pro";
  min-height: 100%;
}

.buffer {
  margin: 1% 5% 0 5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  object-fit: cover;
}

.circle {
  border-radius: 100%;
}

.navbar a {
  color: var(--white);
}

a {
  color: var(--secondary);
}

a:hover {
  text-decoration: none;
}

.active {
  font-weight: 700;
  color: (--primary);
}

h1 {
  font-family: "Spartan";
  font-weight: 900;
  font-size: 48;
  letter-spacing: 5px;
  text-transform: lowercase;
  color: var(--primary);
  margin: 0;
}

h2 {
  font-family: "Source Sans Pro";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36;
  letter-spacing: 2px;
  color: var(--black);
}

h3 {
  font-family: "Spartan";
  font-weight: 700;
  font-size: 28;
  letter-spacing: 3px;
  text-transform: lowercase;
  color: var(--black);
}

p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18;
  letter-spacing: 1px;
  color: var(--black);
  margin: 5px;
}

footer {
  text-align: center;
  margin-top: 5%;
  background: var(--primary);
  color: var(--white);
  padding: 1% 5%;
}

.header {
  text-align: center;
  padding-top: 30px;
}

.full-center {
  justify-content: center;
  align-items: center;
}

.link {
  background: var(--nude);
  color: var(--black);
  width: 100%;
  min-height: 50px;
  margin-top: 10px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  padding: 2%;
}

.link:hover, .link:focus {
  background: var(--primary);
  color: var(--white);
  text-decoration: none;
}

.link-text:hover, .link-text:focus {
  color: inherit;
}

.primary {
  color: var(--primary)
}

.secondary {
  color: var(--secondary)
}

.black {
  color: var(--black)
}

.white {
  color: var(--white)
}

@media screen and (max-width: 992px) {
  h1 {
    font-size: 32;
  }
  h2 {
    font-size: 24;
  }
  h3 {
    font-size: 18;
  }
  p {
    font-size: 14;
  }
}